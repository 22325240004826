import React from "react";
import { ChevronDownIcon, UserIcon } from "@heroicons/react/20/solid";

const Avatar = ({ user }) => {
  return (
    <div className="-m-1.5 flex items-center p-1.5">
      <span className="sr-only">Open user menu</span>
      {user?.profilePicture ? (
        <img
          alt=""
          src={user.profilePicture}
          className="h-8 w-8 rounded-full bg-gray-50"
        />
      ) : (
        <UserIcon className="h-8 w-8 rounded-full bg-gray-50 text-gray-400" />
      )}
      <span className="hidden lg:flex lg:items-center">
        <span
          aria-hidden="true"
          className="ml-4 text-sm font-semibold leading-6 text-gray-900"
        >
          {user?.name}
        </span>
        <ChevronDownIcon
          aria-hidden="true"
          className="ml-2 h-5 w-5 text-gray-400"
        />
      </span>
    </div>
  );
};

export default Avatar;
