import React, { useState, useRef, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import { ClipLoader } from "react-spinners";
import Swal from "sweetalert2";
import Cookies from "js-cookie";

const PhotoUpload = ({
  loggedInUser,
  addPhotos,
  deletePhoto,
  selectedPhotosCount,
  eventId,
}) => {
  const [uploading, setUploading] = useState(false);
  const [totalFiles, setTotalFiles] = useState(0);
  const [uploadedFiles, setUploadedFiles] = useState(0);
  const [isDragActive, setIsDragActive] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [userIdState, setUserIdState] = useState(Cookies.get("user_id"));
  const [sessionIdState, setSessionIdState] = useState(Cookies.get("session_id"));
  const fileInputRef = useRef(null);

  const maxConcurrentUploads = 5; // Number of files to upload concurrently

  const onDrop = useCallback((acceptedFiles, fileRejections) => {
    const invalidFiles = fileRejections.filter(
      (file) => !file.file.type.startsWith("image/")
    );
    if (invalidFiles.length > 0) {
      setErrorMessage("Only image files are allowed");
      setIsDragActive(false);
    } else {
      handleUpload(acceptedFiles);
      setErrorMessage("");
    }
  }, []);

  const { getRootProps, getInputProps, isDragAccept } = useDropzone({
    onDrop,
    accept: "image/*",
    onDragEnter: () => setIsDragActive(true),
    onDragLeave: () => setIsDragActive(false),
    noClick: true,
  });

  const handleFileChange = (e) => {
    const files = e.target.files;
    const invalidFiles = Array.from(files).filter(
      (file) => !file.type.startsWith("image/")
    );
    if (invalidFiles.length > 0) {
      setErrorMessage("Only image files are allowed");
      return;
    }
    handleUpload(files);
  };

  const handleUpload = async (files) => {
    if (files.length > 5000) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "You can only upload up to 5,000 images at a time.",
      });
      return;
    }

    setUploading(true);
    setTotalFiles(files.length);
    setUploadedFiles(0);

    let uploadedCount = 0;

    const fileArray = Array.from(files);
    const fileChunks = chunkArray(fileArray, maxConcurrentUploads); // Break files into batches

    try {
      for (const chunk of fileChunks) {
        await Promise.all(
          chunk.map(async (file) => {
            const wasUploaded = await uploadFile(file, uploadedCount);
            if (wasUploaded) {
              uploadedCount++;
            }
          })
        );
      }
      setSuccessMessage(`${uploadedCount} image(s) uploaded successfully.`);
    } catch (error) {
      setErrorMessage("Some files failed to upload. Please try again.");
    } finally {
      setUploading(false);
      if (fileInputRef.current) {
        fileInputRef.current.value = ""; // Clear the file input
      }
      setTimeout(() => {
        setSuccessMessage("");
      }, 3000); // Clear success message after 3 seconds
    }
  };

  const uploadFile = async (file, currentUploadedCount) => {
    const fileFormData = new FormData();
    fileFormData.append("file[]", file);
    fileFormData.append("event_id", eventId);
    fileFormData.append("user_id", userIdState);
    fileFormData.append("session_id", sessionIdState);
    fileFormData.append("uploadedCount", currentUploadedCount); // Pass the current uploaded count to the server

    let retryCount = 0;
    const maxRetries = 3;
    let uploadSuccess = false;

    while (retryCount < maxRetries && !uploadSuccess) {
      try {
        const response = await axios.post(
          "https://api.uplup.com/hosts/upload.php",
          fileFormData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (response.data.success) {
          setUploadedFiles((prev) => prev + 1); // Update the successfully uploaded files count on the client side
          addPhotos(response.data.images); // Add the uploaded images to the gallery or list
          uploadSuccess = true; // Mark the upload as successful
          return true; // Indicate the file was successfully uploaded
        } else {
          throw new Error(response.data.message); // Trigger a retry if the response is unsuccessful
        }
      } catch (error) {
        retryCount++;
        if (retryCount >= maxRetries) {
          console.error(`Failed to upload file: ${file.name}. Error:`, error);
          setErrorMessage(`Failed to upload some files. Please try again.`);
          return false; // Indicate the file was not successfully uploaded
        }
      }
    }
  };

  // Utility function to chunk an array into batches
  const chunkArray = (array, chunkSize) => {
    return array.reduce((acc, _, i) => {
      if (i % chunkSize === 0) acc.push(array.slice(i, i + chunkSize));
      return acc;
    }, []);
  };

  return (
    <div className="mb-4 relative">
      {uploading && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-75 z-50">
          <ClipLoader color={"#ffffff"} loading={uploading} size={50} />
          <div className="text-white mt-4">
            &nbsp;&nbsp;{`${uploadedFiles} of ${totalFiles} images uploaded...`}
          </div>
        </div>
      )}
      {errorMessage && (
        <div
          className="mt-2 mb-4 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
          role="alert"
        >
          <span className="block sm:inline">{errorMessage}</span>
          <span
            className="absolute top-0 bottom-0 right-0 px-4 py-3"
            onClick={() => setErrorMessage("")}
          >
            <svg
              className="fill-current h-6 w-6 text-red-500"
              role="button"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <title>Close</title>
              <path d="M14.348 14.849a1 1 0 01-1.414 0L10 11.414l-2.933 2.935a1 1 0 01-1.414-1.414L8.586 10 5.652 7.067a1 1 0 011.414-1.414L10 8.586l2.933-2.933a1 1 0 011.414 1.414L11.414 10l2.933 2.933a1 1 0 010 1.414z" />
            </svg>
          </span>
        </div>
      )}
      {successMessage && (
        <div
          className="mt-2 mb-4 bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative"
          role="alert"
        >
          <span className="block sm:inline">{successMessage}</span>
          <span
            className="absolute top-0 bottom-0 right-0 px-4 py-3"
            onClick={() => setSuccessMessage("")}
          >
            <svg
              className="fill-current h-6 w-6 text-green-500"
              role="button"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <title>Close</title>
              <path d="M14.348 14.849a1 1 0 01-1.414 0L10 11.414l-2.933 2.935a1 1 0 01-1.414-1.414L8.586 10 5.652 7.067a1 1 0 011.414-1.414L10 8.586l2.933-2.933a1 1 0 011.414 1.414L11.414 10l2.933 2.933a1 1 0 010 1.414z" />
            </svg>
          </span>
        </div>
      )}

      {selectedPhotosCount > 0 && (
        <div className="flex items-center justify-center mb-4">
          <button
            onClick={deletePhoto}
            className="ml-2 px-4 py-2 bg-red-500 text-white rounded"
          >
            Delete {selectedPhotosCount} Photo{selectedPhotosCount > 1 && "s"}
          </button>
        </div>
      )}

      <p className="text-gray-500 text-left mb-4" style={{ fontSize: 14 }}>
        * Duplicate images will be ignored
      </p>

      <div
        {...getRootProps({
          className: `dropzone border-dashed border-2 rounded p-6 text-center cursor-pointer transition-all duration-300 ${
            isDragActive || isDragAccept
              ? "border-blue-500 bg-blue-50"
              : "border-gray-300 hover:bg-gray-100"
          }`,
          onClick: (e) => {
            e.stopPropagation();
            fileInputRef.current && fileInputRef.current.click();
          },
        })}
      >
        {" "}
        <input {...getInputProps()} />
        <p className="text-gray-500">
          Drag & drop photos here (up to 5,000 at a time)
        </p>
      </div>
      <input
        type="file"
        multiple
        onChange={handleFileChange}
        ref={fileInputRef}
        accept="image/*"
        className="mt-2 p-2 border rounded w-full hidden"
      />
      {uploading && (
        <div className="mt-2">
          <div className="w-full bg-gray-200 rounded-full">
            <div
              className="bg-blue-500 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full"
              style={{ width: `${(uploadedFiles / totalFiles) * 100}%` }}
            >
              {`${Math.round((uploadedFiles / totalFiles) * 100)}%`}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PhotoUpload;
