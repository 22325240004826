"use client";

import React, { useState, useEffect } from "react";
import { useUser } from "../context/UserContext";
import { usePricing } from "../context/PricingModalContext";
import axios from "axios";
import Header from "./template/Header";
import SideBar from "./template/SideBar";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import QRCode from "react-qr-code";
import Modal from "react-modal";
import Cookies from "js-cookie";
import ClipLoader from "react-spinners/ClipLoader";
import { HiPlus } from "react-icons/hi";

const Dashboard = () => {
  const { state, login, logout, updateUser } = useUser();
  const { isLoggedIn, user: loggedInUser } = state;
  const { pricingPageOpen, openPricingModal, closePricingModal } = usePricing();
  const navigate = useNavigate();

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [events, setEvents] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [qrCodeUrl, setQrCodeUrl] = useState("");
  const [userId, setUserId] = useState(Cookies.get("user_id"));
  const [sessionId, setSessionId] = useState(Cookies.get("session_id"));

  if (!userId || !sessionId) {
    logout();
    navigate("/login");
  }

  useEffect(() => {
    updateUser(Cookies.get("user_id"), Cookies.get("session_id"));
  }, [isLoggedIn]);

  useEffect(() => {
    if (loggedInUser) {
      fetchEvents();
    }
  }, [loggedInUser]);

  const fetchEvents = async () => {
    try {
      const response = await axios.get(
        "https://api.uplup.com/hosts/fetch_all_events.php",
        {
          params: {
            user_id: Cookies.get("user_id"),
            session_id: Cookies.get("session_id"),
          },
        }
      );
      if (response.data.success) {
        setEvents(response.data.events);
      } else {
        alert(
          "Error fetching events. Please check the console for more details."
        );
      }
    } catch (error) {
      alert(
        "Error fetching events. Please check the console for more details."
      );
    }
  };

  const deleteEvent = async (event_id) => {
    try {
      const response = await axios.post(
        "https://api.uplup.com/hosts/delete_event.php",
        {
          event_id,
          user_id: Cookies.get("user_id"),
          session_id: Cookies.get("session_id"),
        }
      );

      if (response.data.success) {
        setEvents(events.filter((event) => event.event_id !== event_id));
        Swal.fire("Success!", "Event deleted successfully.", "success");
      } else {
        Swal.fire("Error!", "Failed to delete the event.", "error");
      }
    } catch (error) {
      console.log(error);
      Swal.fire("Error!", "Failed to delete the event.", "error");
    }
  };

  const confirmDelete = (event_id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteEvent(event_id);
      }
    });
  };

  const openQrCodeModal = (url) => {
    setQrCodeUrl(url);
    setModalIsOpen(true);
  };

  const closeQrCodeModal = () => {
    setModalIsOpen(false);
    setQrCodeUrl("");
  };

  const downloadQrCode = () => {
    const svg = document.getElementById("qrCode");
    const serializer = new XMLSerializer();
    const svgBlob = new Blob([serializer.serializeToString(svg)], {
      type: "image/svg+xml",
    });
    const svgUrl = URL.createObjectURL(svgBlob);
    const downloadLink = document.createElement("a");
    downloadLink.href = svgUrl;
    downloadLink.download = "qr_code.svg";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <div>
      <SideBar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <div className="lg:pl-72">
        <Header loggedInUser={loggedInUser} setSidebarOpen={setSidebarOpen} />
        <main className="py-10">
          <div className="px-4 sm:px-6 lg:px-8">
            {!isLoggedIn ? (
              <div className="flex justify-center items-center">
                <ClipLoader color={"#000000"} loading={true} size={50} />
              </div>
            ) : (
              <>
                <h1 className="text-2xl font-bold mb-6">
                  Hello, {loggedInUser?.name}!
                </h1>
                {events.length == 0 ? (
                  <>
                    {/* Button to create a new event */}
                    <div className="flex justify-left items-left">
                      <Link
                        to="/manage-event"
                        className="text-center text-white bg-indigo-600 hover:bg-indigo-700 py-2 px-4 rounded-md"
                      >
                        <HiPlus
                          className="inline-block"
                          style={{ marginTop: -3 }}
                        />
                        &nbsp; Create a New Event
                      </Link>
                    </div>
                  </>
                ) : (
                  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-6">
                    {events.map((event) => (
                      <div
                        key={event.event_id}
                        className="bg-white shadow-md rounded-lg overflow-hidden"
                      >
                        <div className="p-4">
                          <h2 className="text-lg font-semibold mb-2">
                            {event.event_title}
                          </h2>
                          <div className="flex flex-col space-y-2 mt-4">
                            <Link
                              to={`/event/${event.event_id}`}
                              target="_blank"
                              className="text-center text-white bg-indigo-600 hover:bg-indigo-700 py-2 px-4 rounded-md"
                            >
                              View Landing Page
                            </Link>
                            <Link
                              to={`/manage-event?event_id=${event.event_id}`}
                              className="text-center text-white bg-indigo-600 hover:bg-indigo-700 py-2 px-4 rounded-md"
                            >
                              Edit
                            </Link>
                            <Link
                              to={`/submissions/${event.event_id}`}
                              className="text-center text-white bg-indigo-600 hover:bg-indigo-700 py-2 px-4 rounded-md"
                            >
                              Manage Submissions
                            </Link>
                            <button
                              onClick={() =>
                                openQrCodeModal(
                                  `https://app.uplup.com/event/${event.event_id}`
                                )
                              }
                              className="text-center text-white bg-indigo-600 hover:bg-indigo-700 py-2 px-4 rounded-md"
                            >
                              QR Code
                            </button>
                            <button
                              onClick={() => confirmDelete(event.event_id)}
                              className="text-center text-white bg-red-600 hover:bg-red-700 py-2 px-4 rounded-md"
                            >
                              Delete
                            </button>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </>
            )}
          </div>
        </main>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeQrCodeModal}
        contentLabel="QR Code Modal"
        className="flex justify-center items-center"
        overlayClassName="fixed inset-0 bg-gray-500 bg-opacity-75 flex justify-center items-center"
      >
        <div className="bg-white p-4 rounded shadow-md text-center">
          <h2 className="text-lg font-semibold mb-4">Event QR Code</h2>
          {qrCodeUrl && (
            <>
              <QRCode id="qrCode" value={qrCodeUrl} size={300} />
              <button
                onClick={downloadQrCode}
                className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              >
                Download QR Code
              </button>
            </>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default Dashboard;
