import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ManageEvent from "./components/ManageEvent";
import Event from "./components/Event";
import Dashboard from "./components/Dashboard";
import Login from "./components/Login";
import SignUp from "./components/SignUp";
import ForgotPassword from "./components/ForgotPassword";
import ResetPassword from "./components/ResetPassword";
import AttendeePhotos from "./components/AttendeePhotos";
import Profile from "./components/Profile";
import { UserProvider } from "./context/UserContext";
import { PricingModalProvider } from "./context/PricingModalContext";

const App = () => {
  return (
    <UserProvider>
      <PricingModalProvider>
        <Router>
          <Routes>
            <Route path="/manage-event" element={<ManageEvent />} />
            <Route path="/event/:event_id" element={<Event />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/attendee-photos" element={<AttendeePhotos />} />
            <Route path="/profile" element={<Profile />} />
          </Routes>
        </Router>
      </PricingModalProvider>
    </UserProvider>
  );
};

export default App;
