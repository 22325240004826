import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import ClipLoader from "react-spinners/ClipLoader";
import { Modal, Button } from "react-bootstrap";
import { FaArrowLeft, FaArrowRight, FaDownload } from "react-icons/fa";
import Swal from "sweetalert2";

const AttendeePhotoGallery = ({ photos, onPhotoClick }) => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
      {photos.map((photo, index) => (
        <div
          key={index}
          onClick={() => onPhotoClick(index)}
          className="relative cursor-pointer"
          style={{ height: "200px", overflow: "hidden" }}
        >
          <img
            src={photo.url_400}
            alt={`Photo ${index + 1}`}
            className="w-full h-full object-cover"
          />
        </div>
      ))}
    </div>
  );
};

const AttendeePhotos = () => {
  const location = useLocation();
  const [photos, setPhotos] = useState([]);
  const [selectedPhotos, setSelectedPhotos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [lightboxIndex, setLightboxIndex] = useState(0);
  const [nameEnc, setNameEnc] = useState("");
  const [creatingZip, setCreatingZip] = useState(false);
  const [downloadingImage, setDownloadingImage] = useState(false);

  const query = new URLSearchParams(location.search);
  const event_id = query.get("event_id");
  const participant_id = query.get("participant_id");

  useEffect(() => {
    if (window.location.pathname.includes("/attendee-photos")) {
      import("bootstrap/dist/css/bootstrap.min.css");
    }

    const fetchPhotos = async () => {
      try {
        const response = await axios.get(
          `https://api.uplup.com/hosts/fetch_attendee_photos.php`,
          {
            params: { event_id, participant_id },
          }
        );
        console.log(response.data);

        if (response.data.success) {
          const photos = JSON.parse(response.data.image_json);
          setPhotos(photos);
          setNameEnc(response.data.name_enc);
        } else {
          setError("Failed to fetch photos.");
        }
      } catch (error) {
        console.error("An error occurred while fetching photos:", error);
        setError("Failed to fetch photos.");
      } finally {
        setLoading(false);
      }
    };

    fetchPhotos();
  }, []);

  const handlePhotoClick = (index) => {
    setLightboxIndex(index);
    setLightboxOpen(true);
  };

  const handleDownloadAll = async () => {
    setCreatingZip(true);
    const zip = new JSZip();
    const imgFolder = zip.folder("photos");

    const fetchImage = async (url, fileName) => {
      const response = await axios.get(url, { responseType: "blob" });
      imgFolder.file(fileName, response.data);
    };

    try {
      await Promise.all(
        photos.map((photo) => fetchImage(photo.url, photo.file_name))
      );

      const content = await zip.generateAsync({ type: "blob" });
      saveAs(content, "photos.zip");
    } catch (error) {
      console.error("Error downloading all images:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to download all images. Please try lowering your browser's security settings or try a different browser.",
      });
    } finally {
      setCreatingZip(false);
    }
  };

  const handleDownloadImage = async (url, fileName) => {
    setDownloadingImage(true);
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const link = document.createElement("a");
      const objectURL = URL.createObjectURL(blob);
      link.href = objectURL;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(objectURL); // Clean up the URL.createObjectURL object
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to download image. Please try lowering your browser's security settings or try a different browser.",
      });
    } finally {
      setDownloadingImage(false);
    }
  };

  const handlePrev = () => {
    setLightboxIndex((lightboxIndex + photos.length - 1) % photos.length);
  };

  const handleNext = () => {
    setLightboxIndex((lightboxIndex + 1) % photos.length);
  };

  return (
    <div>
      <main className="py-10">
        <div className="px-12">
          <div className="max-w-6xl mx-auto mt-10">
            <h1 className="text-2xl font-bold mb-4">Hello, {nameEnc}!</h1>
            <p>View or download photos of you from the event.</p>
            <button
              className="btn btn-primary mb-4"
              onClick={handleDownloadAll}
              disabled={creatingZip}
            >
              {creatingZip ? (
                <>
                  <ClipLoader color={"#ffffff"} loading={true} size={20} />
                  <span className="ml-2">Creating Zip...</span>
                </>
              ) : (
                "Download All Images"
              )}
            </button>
            {loading ? (
              <p>Loading...</p>
            ) : error ? (
              <p className="text-red-500">{error}</p>
            ) : (
              <AttendeePhotoGallery
                photos={photos}
                selectedPhotos={selectedPhotos}
                setSelectedPhotos={setSelectedPhotos}
                onPhotoClick={handlePhotoClick}
              />
            )}
            <Modal
              show={lightboxOpen}
              onHide={() => setLightboxOpen(false)}
              centered
              size="xl"
              dialogClassName="modal-fullscreen"
              contentClassName="modal-content-fullscreen"
            >
              <Modal.Header closeButton>
                <Modal.Title>Photo Viewer</Modal.Title>
              </Modal.Header>
              <Modal.Body className="d-flex justify-content-center align-items-center">
                {photos[lightboxIndex] && (
                  <img
                    src={photos[lightboxIndex].url_900}
                    alt={`Photo ${lightboxIndex + 1}`}
                    className="img-fluid"
                    style={{ maxHeight: "80vh" }}
                  />
                )}
              </Modal.Body>
              <Modal.Footer className="justify-content-between">
                <Button
                  variant="secondary"
                  onClick={handlePrev}
                  className="d-flex align-items-center"
                >
                  <FaArrowLeft className="me-2" /> Prev
                </Button>
                <Button
                  variant="primary"
                  onClick={() =>
                    handleDownloadImage(
                      photos[lightboxIndex].url,
                      photos[lightboxIndex].file_name
                    )
                  }
                  disabled={downloadingImage}
                  className="d-flex align-items-center"
                >
                  {downloadingImage ? (
                    <ClipLoader color={"#ffffff"} loading={true} size={20} />
                  ) : (
                    <>
                      <FaDownload className="me-2" /> Download Image
                    </>
                  )}
                </Button>
                <Button
                  variant="secondary"
                  onClick={handleNext}
                  className="d-flex align-items-center"
                >
                  Next <FaArrowRight className="ms-2" />
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </main>
    </div>
  );
};

export default AttendeePhotos;
