import React, {
  createContext,
  useReducer,
  useContext,
  useState,
  useEffect,
} from "react";
import axios from "axios";
import Cookies from "js-cookie";

const UserContext = createContext({
  state: {
    isLoggedIn: false,
    user: null,
    user_brand: null,
    all_user_brands: null,
    link_add_on: "",
    link_add_on_additional: "",
    is_shopify: false,
    loading: true, // Assume loading state by default
  },
  dispatch: () => null,
  login: () => null,
  logout: () => null,
  updateUser: () => null,
});

const initialState = {
  user: null,
  isLoggedIn: false,
  loading: true,
};

const reducer = (state, action) => {
  var isInIframe = window.self !== window.top;
  var linkAddOn = "";
  var linkAddOnAdditional = "";
  var isShopify = false;

  // Is in iframe
  if (isInIframe) {
    // Get user_id and session_id parameters from URL
    const urlParams = new URLSearchParams(window.location.search);
    var user_id = urlParams.get("user_id");
    var session_id = urlParams.get("session_id");
    var isLoggedIn = urlParams.get("isLoggedIn") === "true";
    var activeBrandId = urlParams.get("activeBrandId");
    isShopify = urlParams.get("isShopify") === "true";
    linkAddOn = `?user_id=${user_id}&session_id=${session_id}&activeBrandId=${activeBrandId}&isLoggedIn=${isLoggedIn}&isShopify=${isShopify}`;
    linkAddOnAdditional = `&user_id=${user_id}&session_id=${session_id}&activeBrandId=${activeBrandId}&isLoggedIn=${isLoggedIn}&isShopify=${isShopify}`;
    isShopify = true;
  }

  switch (action.type) {
    case "LOGIN":
      return {
        ...state,
        user: action.payload.user,
        user_brand: action.payload.user_brand,
        all_user_brands: action.payload.all_user_brands,
        link_add_on: linkAddOn,
        link_add_on_additional: linkAddOnAdditional,
        is_shopify:
          action.payload.user?.payment_platform == "shopify" ? true : isShopify,
        isLoggedIn: true,
      };
    case "UPDATE_USER":
      return {
        ...state,
        user: action.payload.user,
        user_brand: action.payload.user_brand,
        all_user_brands: action.payload.all_user_brands,
        link_add_on: action.payload.link_add_on,
        link_add_on_additional: action.payload.link_add_on_additional,
        isLoggedIn: true,
      };

    case "LOGOUT":
      return {
        ...state,
        user: null,
        isLoggedIn: false,
      };
    case "SET_LOADING":
      return {
        ...state,
        loading: action.payload,
      };
    default:
      throw new Error(`Unsupported action type: ${action.type}`);
  }
};

const useUser = () => {
  return useContext(UserContext);
};

export { useUser };

export const UserProvider = ({ children }) => {
  // Get brandId from URL
  const urlParams = new URLSearchParams(window.location.search);
  const brandId = urlParams.get("brandId");

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (brandId == "x2s4x112") {
      var user_id = "vcifvmuydk49wnliel1w3ug0wtk26y08nawa7ooh";
      var session_id = "iiff36bxp4tua69br9u5axme3ny2h7l2fq7rpxtw";
      var active_brand_id = "x2s4x112";
      var isLoggedIn = true;
      var linkAddOn = "";
      var linkAddOnAdditional = "";
      var isShopify = false;
    }

    // Regular request
    else {
      var isInIframe = window.self !== window.top;
      if (!isInIframe) {
        var isLoggedIn = false;
        var user_id = Cookies.get("user_id");
        var session_id = Cookies.get("session_id");
        // var active_brand_id = Cookies.get("active_brand_id");

        // Set isLoggedIn here
        if (
          user_id !== undefined &&
          session_id !== undefined &&
          // active_brand_id !== undefined &&
          user_id !== null &&
          session_id !== null &&
          // active_brand_id !== null &&
          user_id !== "" &&
          session_id !== ""
          // active_brand_id !== ""
        ) {
          isLoggedIn = true;
        }

        var linkAddOn = "";
        var linkAddOnAdditional = "";
        var isShopify = false;
      } else {
        // Get user_id and session_id parameters from URL
        const urlParams = new URLSearchParams(window.location.search);
        var user_id = urlParams.get("user_id");
        var session_id = urlParams.get("session_id");
        var isLoggedIn = urlParams.get("isLoggedIn") === "true";
        var isShopify = urlParams.get("isShopify") === "true";
        var activeBrandId = urlParams.get("activeBrandId");
        var linkAddOn = `?user_id=${user_id}&session_id=${session_id}&activeBrandId=${activeBrandId}&isLoggedIn=${isLoggedIn}&isShopify=${isShopify}`;
        var linkAddOnAdditional = `&user_id=${user_id}&session_id=${session_id}&activeBrandId=${activeBrandId}&isLoggedIn=${isLoggedIn}&isShopify=${isShopify}`;
      }
    }

    if (isLoggedIn) {
      const fetchUserData = async () => {
        try {
          const response = await axios.post(
            "https://api.uplup.com/hosts/get_user_details.php",
            {
              user_id,
              session_id,
            }
          );

          if (response.data.success) {
            dispatch({
              type: "LOGIN",
              payload: {
                user: response.data.user_details.user,
                user_brand: response.data.user_details.user_brand,
                all_user_brands: response.data.user_details.all_user_brands,
                link_add_on: linkAddOn,
                link_add_on_additional: linkAddOnAdditional,
                is_shopify:
                  response.data.user_details.user.payment_platform == "shopify"
                    ? true
                    : isShopify,
              },
            });
          } else {
            console.error("Error fetching user data:", response.data.message);
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        } finally {
          dispatch({ type: "SET_LOADING", payload: false });
        }
      };

      fetchUserData();
    } else {
      dispatch({ type: "SET_LOADING", payload: false });
    }
  }, [brandId]);

  const login = (userData) => {
    dispatch({ type: "LOGIN", payload: userData });
  };

  const logout = () => {
    dispatch({ type: "LOGOUT" });

    var isInIframe = window.self !== window.top;
    if (!isInIframe) {
      Cookies.remove("user_id");
      Cookies.remove("session_id");
      Cookies.remove("active_brand_id");
      Cookies.remove("PHPSESSID");
    }
  };

  const updateUser = async (userId, sessionId) => {
    var isInIframe = window.self !== window.top;
    var linkAddOn = "";
    var linkAddOnAdditional = "";
    var isShopify = false;

    // Is in iframe
    if (isInIframe) {
      // Get user_id and session_id parameters from URL
      const urlParams = new URLSearchParams(window.location.search);
      var user_id = urlParams.get("user_id");
      var session_id = urlParams.get("session_id");
      var isLoggedIn = urlParams.get("isLoggedIn") === "true";
      var activeBrandId = urlParams.get("activeBrandId");
      linkAddOn = `?user_id=${user_id}&session_id=${session_id}&activeBrandId=${activeBrandId}&isLoggedIn=${isLoggedIn}&isShopify=${isShopify}`;
      linkAddOnAdditional = `&user_id=${user_id}&session_id=${session_id}&activeBrandId=${activeBrandId}&isLoggedIn=${isLoggedIn}&isShopify=${isShopify}`;
      isShopify = urlParams.get("isShopify") === "true";
    }

    try {
      const response = await axios.post(
        "https://api.uplup.com/hosts/get_user_details.php",
        {
          user_id: userId,
          session_id: sessionId,
        }
      );

      if (response.data.success) {
        dispatch({
          type: "UPDATE_USER",
          payload: {
            user: response.data.user_details.user,
            user_brand: response.data.user_details.user_brand,
            all_user_brands: response.data.user_details.all_user_brands,
            link_add_on: linkAddOn,
            link_add_on_additional: linkAddOnAdditional,
            is_shopify:
              response.data.user_details.user.payment_platform == "shopify"
                ? true
                : isShopify,
          },
        });
      } else {
        //
      }
    } catch (error) {
      //
    }
  };

  const value = {
    state,
    dispatch,
    login,
    logout,
    updateUser,
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
