import React from "react";

const PhotoGallery = ({ photos, selectedPhotos, setSelectedPhotos }) => {
  const handlePhotoClick = (image_id) => {
    if (selectedPhotos.includes(image_id)) {
      setSelectedPhotos(selectedPhotos.filter((id) => id !== image_id));
    } else {
      setSelectedPhotos([...selectedPhotos, image_id]);
    }
  };

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
      {photos.map((photo, index) => (
        <div
          key={index}
          onClick={() => handlePhotoClick(photo.image_id)}
          className={`relative cursor-pointer ${
            selectedPhotos.includes(photo.image_id)
              ? "outline outline-4 outline-blue-500"
              : ""
          }`}
          style={{ height: "200px", overflow: "hidden" }} // Fixed height
        >
          <img
            src={photo.thumbnail_400_path}
            alt={`Photo ${index + 1}`}
            className="w-full h-full object-cover" // Ensure the image fits without stretching
          />
        </div>
      ))}
    </div>
  );
};

export default PhotoGallery;
