import React from "react";
import { useDrag, useDrop } from "react-dnd";
import {
  FaGripVertical,
  FaTextWidth,
  FaAlignLeft,
  FaCheckSquare,
  FaDotCircle,
  FaCaretSquareDown,
} from "react-icons/fa";

const elementIcons = {
  text: <FaTextWidth />,
  textarea: <FaAlignLeft />,
  checkbox: <FaCheckSquare />,
  radio: <FaDotCircle />,
  select: <FaCaretSquareDown />,
};

const FormElement = ({
  element,
  moveElement,
  updateElement,
  deleteElement,
}) => {
  const [{ isDragging }, drag] = useDrag({
    type: "form-element",
    item: { id: element.id },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: "form-element",
    hover: (draggedItem) => {
      if (draggedItem.id !== element.id) {
        moveElement(draggedItem.id, element.id);
      }
    },
  });

  const handleLabelChange = (e) => {
    updateElement(element.id, { ...element, label: e.target.value });
  };

  const handleOptionChange = (index, value) => {
    const newOptions = [...element.options];
    newOptions[index] = value;
    updateElement(element.id, { ...element, options: newOptions });
  };

  const addOption = () => {
    updateElement(element.id, {
      ...element,
      options: [...element.options, ""],
    });
  };

  const removeOption = (index) => {
    const newOptions = element.options.filter((_, i) => i !== index);
    updateElement(element.id, { ...element, options: newOptions });
  };

  const handleRequiredChange = (e) => {
    updateElement(element.id, { ...element, required: e.target.checked });
  };

  return (
    <div
      ref={(node) => drag(drop(node))}
      className={`form-element mb-4 p-4 border rounded bg-white ${
        isDragging ? "opacity-50" : "opacity-100"
      }`}
    >
      <div className="flex items-center mb-2">
        <FaGripVertical className="mr-2 cursor-move" />
        {elementIcons[element.type]}
        <span className="ml-2 text-gray-700">{element.label}</span>
      </div>
      <input
        type="text"
        value={element.label}
        onChange={handleLabelChange}
        className="block w-full p-2 border rounded mb-2"
        disabled={element.disabled}
      />
      {element.type === "radio" || element.type === "select" ? (
        <div className="mt-2">
          {element.options.map((option, index) => (
            <div key={index} className="flex items-center mb-1">
              <input
                type="text"
                value={option}
                onChange={(e) => handleOptionChange(index, e.target.value)}
                className="block w-full p-2 border rounded"
              />
              <button
                onClick={() => removeOption(index)}
                className="ml-2 text-red-600"
              >
                Remove
              </button>
            </div>
          ))}
          <button onClick={addOption} className="mt-2 text-blue-600">
            Add Option
          </button>
        </div>
      ) : null}
      <div className="flex items-center mt-2">
        <label style={{ marginTop: 10, cursor: "pointer" }}>
          <input
            type="checkbox"
            checked={element.required}
            onChange={handleRequiredChange}
            className="mr-3"
            style={{ width: "inherit", cursor: "pointer" }}
          />
          <span>Required?</span>
        </label>
      </div>
      <div className="flex justify-end">
        <button
          onClick={() => deleteElement(element.id)}
          className="text-red-600 mt-2"
          disabled={element.disabled}
        >
          Remove
        </button>
      </div>
    </div>
  );
};

export default FormElement;
