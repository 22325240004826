import { useState, useEffect, useRef } from "react";
import { useUser } from "../context/UserContext";
import { usePricing } from "../context/PricingModalContext";
import Swal from "sweetalert2";
import axios from "axios";
import { ClipLoader } from "react-spinners";
import Footer from "./template/Footer";
import Cookies from "js-cookie";

export default function SignUp() {
  const { state, login } = useUser();
  const { isLoggedIn } = state;
  const { pricingPageOpen, openPricingModal, closePricingModal } = usePricing();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [timeZone, setTimeZone] = useState("");
  const [loading, setLoading] = useState(false);
  const isSubmitting = useRef(false);
  const [userId, setUserId] = useState(Cookies.get("user_id"));
  const [sessionId, setSessionId] = useState(Cookies.get("session_id"));

  useEffect(() => {
    if (userId && sessionId) {
      window.location.href = "/dashboard";
    }
  }, [userId, sessionId]);

  // Get the user's timezone
  useEffect(() => {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setTimeZone(timeZone);
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (isSubmitting.current) return;

    const formData = { name, email, password };

    // Error handle the form data with Swal
    if (!name || !email || !password) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please fill out all fields",
      });
      return;
    }

    setLoading(true);
    isSubmitting.current = true;

    try {
      const response = await axios.post(
        "https://api.uplup.com/hosts/signup.php",
        {
          name,
          email,
          password,
          time_zone: timeZone,
          via: Cookies.get("via"),
        }
      );

      console.log("response", response);

      if (response.data.success) {
        // Set cookies
        Cookies.set("user_id", response.data.user.user_id, { expires: 90 });
        Cookies.set("session_id", response.data.user.session_id, {
          expires: 90,
        });

        login(response.data.user);

        window.location.href = "/dashboard";
      } else {
        // Handle errors, e.g., show an error message to the user
        console.error("Error signing up:", response.statusText);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: response.data.message || "There was an error signing up.",
        });
      }
    } catch (error) {
      console.error("Network error:", error);
      Swal.fire({
        icon: "error",
        title: "Network Error",
        text: "There was a problem connecting to the server.",
      });
    } finally {
      setLoading(false);
      isSubmitting.current = false;
    }
  };

  return (
    <>
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <img
            alt="Uplup"
            src="https://uplup.fra1.cdn.digitaloceanspaces.com/root/uplup-logo.png"
            className="mx-auto h-10 w-auto"
          />
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Sign up for your Uplup account!
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label
                htmlFor="name"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Full Name
              </label>
              <div className="mt-2">
                <input
                  id="name"
                  name="name"
                  type="text"
                  autoComplete="name"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
            </div>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Email address
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Password
                </label>
              </div>
              <div className="mt-2">
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                disabled={loading}
                style={{ cursor: loading ? "not-allowed" : "pointer" }}
              >
                {loading ? (
                  <ClipLoader color={"#ffffff"} loading={true} size={20} />
                ) : (
                  "Sign up"
                )}
              </button>
            </div>
          </form>

          <p className="mt-10 text-center text-sm text-gray-500">
            Already have an account?{" "}
            <a
              href="/login"
              className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
            >
              Sign in
            </a>
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
}
