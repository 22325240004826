import React, { useState, useEffect } from "react";
import { useUser } from "../context/UserContext";
import { usePricing } from "../context/PricingModalContext";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import Swal from "sweetalert2";
import Cookies from "js-cookie";

import Header from "./template/Header";
import SideBar from "./template/SideBar";
import PhotoGallery from "./PhotoGallery";
import PhotoUpload from "./PhotoUpload";
import FormBuilder from "./FormBuilder";

const ManageEvent = () => {
  const { state, login, logout, updateUser } = useUser();
  const { isLoggedIn, user: loggedInUser } = state;
  const { pricingPageOpen, openPricingModal, closePricingModal } = usePricing();
  const navigate = useNavigate();

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [photos, setPhotos] = useState([]);
  const [selectedPhotos, setSelectedPhotos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("Event Information");
  const [eventTitle, setEventTitle] = useState("");
  const [eventDescription, setEventDescription] = useState("");
  const [formData, setFormData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalImages, setTotalImages] = useState(0);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const [eventId, setEventId] = useState(params.get("event_id") || null);
  const [userId, setUserId] = useState(Cookies.get("user_id"));
  const [sessionId, setSessionId] = useState(Cookies.get("session_id"));

  const PHOTOS_PER_PAGE = 20;

  if (!userId || !sessionId) {
    logout();
    navigate("/login");
  }

  useEffect(() => {
    if (eventId == null) {
      // setEventId to 8 char hash
      setEventId(Math.random().toString(36).substring(2, 10).toLowerCase());
    }
  }, []);

  useEffect(() => {
    updateUser(Cookies.get("user_id"), Cookies.get("session_id"));
    if (eventId != null && isLoggedIn) {
      fetchEvent();
    }
  }, [isLoggedIn, currentPage]);

  const fetchEvent = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        "https://api.uplup.com/hosts/fetch_event.php",
        {
          params: {
            event_id: eventId,
            user_id: Cookies.get("user_id"),
            session_id: Cookies.get("session_id"),
            page: currentPage,
            per_page: PHOTOS_PER_PAGE,
          },
        }
      );
      if (response.data.success) {
        const event = response.data.event;
        setPhotos(response.data.images);
        setEventTitle(event.event_title);
        setEventDescription(event.event_description);
        setFormData(event.form_data ? JSON.parse(event.form_data) : []);
        setTotalPages(Math.ceil(response.data.total_images / PHOTOS_PER_PAGE));
        setTotalImages(response.data.total_images);
      } else {
        Swal.fire("Error!", "Failed to fetch the event.", "error");
      }
    } catch (error) {
      Swal.fire("Error!", "Failed to fetch the event.", "error");
    } finally {
      setLoading(false);
    }
  };

  const addPhotos = (newPhotos) => {
    // Prepend new photos
    setPhotos((prevPhotos) => [...newPhotos, ...prevPhotos]);
    setTotalImages((prevTotal) => prevTotal + newPhotos.length);
  };

  const deletePhoto = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        "https://api.uplup.com/hosts/delete_image.php",
        {
          event_id: eventId,
          user_id: Cookies.get("user_id"),
          session_id: Cookies.get("session_id"),
          image_ids: selectedPhotos,
        }
      );
      if (response.data.success) {
        const deletedCount = selectedPhotos.length;
        setPhotos(
          photos.filter((photo) => !selectedPhotos.includes(photo.image_id))
        );
        setSelectedPhotos([]);
        setTotalImages((prevTotal) => prevTotal - deletedCount);
      } else {
        Swal.fire("Error!", "Failed to delete the photos.", "error");
      }
    } catch (error) {
      Swal.fire("Error!", "Failed to delete the photos.", "error");
    } finally {
      setLoading(false);
    }
  };

  const saveChanges = async () => {
    const data = {
      event_id: eventId,
      user_id: Cookies.get("user_id"),
      session_id: Cookies.get("session_id"),
      event_title: eventTitle,
      event_description: eventDescription,
      form_data: formData,
    };

    // Check if empty title
    if (eventTitle === "") {
      Swal.fire("Error!", "Event title cannot be empty.", "error");
      return;
    }

    setLoading(true);

    try {
      const response = await axios.post(
        "https://api.uplup.com/hosts/save_event.php",
        data
      );
      if (response.data.success) {
        setLoading(false);
        Swal.fire("Success!", "Event saved successfully.", "success").then(
          () => {
            window.location.href = `/dashboard`;
          }
        );
      } else {
        setLoading(false);
        // Swal error with html response
        Swal.fire({
          icon: "error",
          title: "Oops...",
          html: response.data.message,
          confirmButtonText: "OK",
        });
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const tabs = [
    { name: "Event Information", key: "Event Information" },
    { name: `Event Photos (${totalImages})`, key: "Event Photos" },
    { name: "Form Questions", key: "Form Questions" },
  ];

  return (
    <div>
      <SideBar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <div className="lg:pl-72">
        <Header loggedInUser={loggedInUser} setSidebarOpen={setSidebarOpen} />
        <main className="py-10">
          <div className="px-4 sm:px-6 lg:px-8">
            {loading && (
              <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-75 z-50">
                <ClipLoader color={"#ffffff"} loading={loading} size={50} />
              </div>
            )}
            <h1 className="text-2xl font-bold mb-4">Event Settings</h1>
            <div className="relative pb-5 sm:pb-0">
              <div className="md:flex md:items-center md:justify-between">
                <div className="mt-3 flex md:absolute md:right-0 md:top-3 md:mt-0">
                  <button
                    type="button"
                    onClick={saveChanges}
                    className="ml-3 inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500"
                  >
                    Save Changes
                  </button>
                </div>
              </div>
              <div className="mt-4">
                <div className="sm:hidden">
                  <label htmlFor="current-tab" className="sr-only">
                    Select a tab
                  </label>
                  <select
                    id="current-tab"
                    name="current-tab"
                    value={activeTab}
                    onChange={(e) => setActiveTab(e.target.value)}
                    className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600"
                  >
                    {tabs.map((tab) => (
                      <option key={tab.key} value={tab.key}>
                        {tab.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="hidden sm:block" style={{ marginBottom: 40 }}>
                  <nav className="-mb-px flex space-x-8">
                    {tabs.map((tab) => (
                      <button
                        key={tab.key}
                        onClick={() => setActiveTab(tab.key)}
                        aria-current={
                          activeTab === tab.key ? "page" : undefined
                        }
                        className={`whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium ${
                          activeTab === tab.key
                            ? "border-indigo-500 text-indigo-600"
                            : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700"
                        }`}
                      >
                        {tab.name}
                      </button>
                    ))}
                  </nav>
                </div>

                {/* Event Information */}
                <div
                  className={
                    activeTab === "Event Information" ? "block mt-4" : "hidden"
                  }
                >
                  <form>
                    <div className="mb-4">
                      <label
                        htmlFor="eventTitle"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Event Title
                      </label>
                      <input
                        type="text"
                        id="eventTitle"
                        value={eventTitle}
                        onChange={(e) => setEventTitle(e.target.value)}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-500 focus:ring-0 sm:text-sm"
                      />
                    </div>
                    <div className="mb-4">
                      <label
                        htmlFor="eventDescription"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Event Description
                      </label>
                      <ReactQuill
                        value={eventDescription}
                        onChange={setEventDescription}
                        className="mt-1"
                        style={{ height: "200px" }}
                      />
                    </div>
                  </form>
                </div>
                {/* Event Photos */}
                <div
                  className={
                    activeTab === "Event Photos" ? "block mt-4" : "hidden"
                  }
                >
                  <PhotoUpload
                    loggedInUser={loggedInUser}
                    addPhotos={addPhotos}
                    deletePhoto={deletePhoto}
                    selectedPhotosCount={selectedPhotos.length}
                    eventId={eventId}
                  />
                  <PhotoGallery
                    photos={Array.isArray(photos) ? photos : []}
                    selectedPhotos={selectedPhotos}
                    setSelectedPhotos={setSelectedPhotos}
                  />
                  {totalPages > 1 && (
                    <div className="flex justify-center mt-4">
                      <button
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                        className="mx-2 px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded hover:bg-indigo-500 disabled:opacity-50"
                      >
                        Previous
                      </button>
                      <span className="mx-2 px-4 py-2 text-sm font-medium text-gray-700">
                        Page {currentPage} of {totalPages} ({totalImages} total)
                      </span>
                      <button
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={currentPage === totalPages}
                        className="mx-2 px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded hover:bg-indigo-500 disabled:opacity-50"
                      >
                        Next
                      </button>
                    </div>
                  )}
                </div>
                {/* Form Questions */}
                <div
                  className={
                    activeTab === "Form Questions" ? "block mt-4" : "hidden"
                  }
                >
                  <p
                    className="text-sm text-gray-600"
                    style={{ marginBottom: 30 }}
                  >
                    * &nbsp;Full name, email address, and phone number are added
                    automatically. You can add additional questions below.
                  </p>
                  <DndProvider backend={HTML5Backend}>
                    <FormBuilder
                      formData={formData}
                      setFormData={setFormData}
                    />
                  </DndProvider>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default ManageEvent;
