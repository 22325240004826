import "./index.css";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";

// Conditionally import Bootstrap CSS
if (window.location.pathname.includes("/event/")) {
  import("bootstrap/dist/css/bootstrap.min.css");
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
