import React, { createContext, useContext, useState } from "react";

const PricingModalContext = createContext();

export const usePricing = () => {
  return useContext(PricingModalContext);
};

export const PricingModalProvider = ({ children }) => {
  const [pricingPageOpen, setPricingPageOpen] = useState(false);

  const openPricingModal = () => setPricingPageOpen(true);
  const closePricingModal = () => setPricingPageOpen(false);

  const value = {
    pricingPageOpen,
    openPricingModal,
    closePricingModal,
  };

  return (
    <PricingModalContext.Provider value={value}>
      {children}
    </PricingModalContext.Provider>
  );
};
